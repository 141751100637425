/* eslint-disable import/no-extraneous-dependencies */

/**
 * Polyfills for ES6
 * If you need to are more polyfills see: https://github.com/zloirock/core-js
 */
require('core-js/es/map');
require('core-js/es/set');
require('core-js/es/object/assign');
require('core-js/es/promise');
