/* globals window, document */
/**
 * Styles
 */
require('../pages/cross-site/styles.scss');

/**
 * Polyfills
 */
require('./polyfills');

/**
 * Module dependencies
 */
const React = require('react');
const { hydrateRoot } = require('nordic/hydrate');
const { AndesProvider } = require('@andes/context');
const I18n = require('nordic/i18n');
const I18nProvider = require('nordic/i18n/I18nProvider');
const CrossSiteView = require('../pages/cross-site/page');

/**
 * Get server state
 */
const { translations, locale, device } = window.__PRELOADED_STATE__;

/**
 * i18n
 */
const i18n = new I18n({ translations });

/**
 * Mount crossSiteView on client
 */
hydrateRoot(
  document.getElementById('root-app'),
  <AndesProvider locale={locale} device={device}>
    <I18nProvider i18n={i18n}>
      <CrossSiteView />
    </I18nProvider>
  </AndesProvider>,
);
