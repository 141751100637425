const React = require('react');
const PropTypes = require('prop-types');
const Head = require('nordic/head');
const serialize = require('serialize-javascript');
const Style = require('nordic/style');
const Script = require('nordic/script');
const { CrossSite } = require('op-frontend-commons');

const CrossSiteView = (props) => {
  const { i18n, locale, device } = props;
  return (
    <div>
      <Head>
        <title>{i18n.gettext('Certified Partner Program')}</title>
        <link
          rel="icon"
          type="image/png"
          href="https://http2.mlstatic.com/ui/navigation/3.0.8/mercadolibre/120x120-precomposed.png"
          sizes="120x120"
        />
        <link href="https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/300-400-600-700.css" rel="stylesheet" />
      </Head>
      <Style href="crosssite.css" />
      <CrossSite
        applicationName={i18n.gettext('Certified Partner Program')}
        countries={[
          {
            href: 'https://partners.mercadolivre.com.br/',
            name: 'Brasil',
          },
          {
            href: 'https://partners.mercadolibre.cl/es_ar/consultant-mlc',
            name: 'Chile',
          },
          {
            href: 'https://partners.mercadolibre.com.co/es_ar/consultant-mco',
            name: 'Colombia',
          },
          {
            href: 'https://partners.mercadolibre.com.ar/',
            name: 'Argentina',
          },
          {
            href: 'https://partners.mercadolibre.com.mx/',
            name: 'México',
          },
          {
            href: 'https://partners.mercadolibre.com.uy/es_ar/consultant-mlu',
            name: 'Uruguay',
          },
        ]}
      />
      <Script>{`window.__PRELOADED_STATE__ = ${serialize({ locale, device }, { isJSON: true })};`}</Script>
      <Script src="vendor.js" />
      <Script src="crosssite.js" />
    </div>
  );
};

CrossSiteView.defaultProps = {
  i18n: {
    gettext: str => str,
  },
};

CrossSiteView.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }),
};

module.exports = CrossSiteView;
